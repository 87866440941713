// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import type { ButtonViewProps } from '../../types/ButtonViewProps'
import type { AreaTypes } from '../../types/enums/AreaTypes'

import { getFontSize, getLineHeight } from '../../styles/style-helpers'
import Ribbon from '../../components/Ribbon/Ribbon'
import Button from '../../components/Button/Button'
import Markdown from '../../components/Markdown/Markdown'

type Props = {
  button: ButtonViewProps,
  text?: string,
  area?: AreaTypes,
}

type State = {}

const ButtonModuleWrapper = styled.div`
  margin-bottom: ${rem(32)};

  &:last-child {
    margin-bottom: 0;
  }
`

const ButtonModuleText = styled(Markdown)`
  font-size: ${getFontSize('small')};
  line-height: ${getLineHeight('normal')};
  margin-bottom: ${rem(16)};
`

class ButtonModule extends React.Component<Props, State> {
  static displayName = 'ButtonModule'
  static defaultProps = {}

  createButton = (props: Props) => {
    const { button, text } = props

    return (
      <ButtonModuleWrapper>
        {text && <ButtonModuleText source={text} noMargin />}
        <Button {...(button || {})} />
      </ButtonModuleWrapper>
    )
  }

  render() {
    const { area } = this.props

    const isSideBarArea = area === 'sidebar'
    const CreatedButton = this.createButton(this.props)

    return (
      <React.Fragment>
        {isSideBarArea ? CreatedButton : <Ribbon>{CreatedButton}</Ribbon>}
      </React.Fragment>
    )
  }
}

export default ButtonModule
